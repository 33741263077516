const About = () => {
  return (
    <div className="about-us-container bg-white pt-20 md:pt-28" id="about">
      <div className="about-us-content">
        <div className="about-us-image">
          <img
            src="./image.png"
            alt="Relaxing therapy session"
            className="therapy-image"
          />
        </div>
        <div className="about-us-text">
          <h2 className="pb-11">Über uns</h2>
          <p className="pb-11">
            Wir konzentrieren uns darauf, Menschen in schwierigen Situationen zu unterstützen. Mit Herz und Verstand, begleiten wir unsere Kunden in Ihren Lebensphasen mit verhaltensändernden Hypnosen, oder durch die Wiederherstellung Ihrer Energie mit Reiki.
            Wir schaffen eine ruhige und vertrauenswürdige Umgebung für Dich und deine Liebsten.
          </p>
          {/* <button className="session-button">Book a Session</button> */}
          {/* <button className="bg-[#F5F5F5] hover:bg-[#F5F5F5] text-[#2C2C2C] w-full max-w-[230px] py-2 rounded-full">
                      Buchen Sie eine Sitzung
                  </button> */}
        </div>
      </div>
    </div>
  );
};


export default About;
