import { useState, useEffect } from "react";



const testimonials = [
  {
    id: 1,
    image:'./t1.png',
    name: "Individuelle Behandlung",
    text: "Alle meine Behandlungen sind individuell auf dich abgestimmt. Bei einem ersten Gespräch lernen wir uns kennen und entscheiden welche Behandlung für dich stimmig ist. Eine Vertrauensbasis und persönliche Ebene zu dir ist mir wichtig, deshalb gibt es bei mir nur DU.",
  },
  {
    id: 2,
    image:'./t2.png',
    name: "Balance & Harmonie",
    text: "Du möchtest etwas für dein Wohlbefinden tun? Du möchtest in Balance und Harmonie kommen? Du möchtest deinen Weg finden? Egal ob du dich für eine Behandlung oder ein Seminar oder eine Ausbildung interessierst, du wirst deinen Herzensweg finden und ihn gehen.",
  },
  {
    id: 3,
    image:'./t3.png',
    name: "Ausbildungen und Seminare in kleinen Gruppen",
    text: "Alle meine Ausbildungen und Seminare finden in kleinen Gruppen und einen geschützten Rahmen statt. Es ist mir ein besonderes Anliegen, dass du dich wohl, sicher und aufgehoben fühlst. Du darfst dich in der Gruppe öffnen und Heilung erfahren.",
  }
];

const TestimonialCarousel = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const visibleTestimonials = 3;
  const totalPages = Math.ceil(testimonials.length / visibleTestimonials);

  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        setCurrentPage((prev) => (prev + 1) % totalPages);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [isPaused, totalPages]);

  const getVisibleTestimonials = () => {
    const startIndex = currentPage * visibleTestimonials;
    return testimonials.slice(startIndex, startIndex + visibleTestimonials);
  };

  return (
    <div className="pt-20 md:pt-28 bg-white" id="testimonials">
      <div className="flex items-center justify-center mb-10">
        {/* <h2 className="text-3xl font-bold textbig pb-20 text-center">Warum sollte ich mich für Esdarf Leichstein entscheiden?</h2> */}
      </div>
      <div
        className="max-w-[1200px] mx-auto bg-[#F5F5F5] rounded-3xl p-12"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >


        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {getVisibleTestimonials().map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white rounded-2xl p-8 shadow-sm border border-gray-100"
            >
              <div className="flex flex-col items-center text-center space-y-4">
                <img
                  src={testimonial.image}
                  alt="Hypnosis Session"
                  className="imageservice"
                />
                <h3 className="font-semibold">{testimonial.name}</h3>
                <p className="text-gray-700 text-sm leading-relaxed mb-4">
                  {testimonial.text}
                </p>

              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center gap-2 mt-8">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={`w-3 h-3 rounded-full transition-colors ${index === currentPage ? "bg-gray-400" : "bg-gray-200"
                }`}
              aria-label={`Go to page ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
