import React from "react";

const Services = () => {
  return (
    <div className="bg-white" id="services">
      <div className="max-w-7xl mx-auto px-4 pt-20 md:pt-28 sm:px-6 lg:px-8">

        <div className="flex items-center justify-center mb-12">
          {/* <h2 className="text-3xl font-bold textbig pb-20">Üsi Dienstleistungen</h2> */}
          <h2 className="text-3xl font-bold textbig pb-20">Welche Möglichkeiten bietet Esdarf Leichstein?</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Service Card 1 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./i4.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Hypnose
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Hypnose hilft dir mit positiven Gedanken und Bildern unerwünschte Gewohnheiten und Verhaltensmuster zu ändern. Positive Gedanken und Bilder werden im Unterbewusstsein verankert. Erlange Selbstvertrauen und Selbstliebe.

            </p>
            {/* <button className="font-medium rounded-full buttonservice">
            zur  Hypnose erfahren
            </button> */}
          </div>
          {/* Service Card 2 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle" >
            <img
              src="./i3.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p className="fontiservicestitle pt-3 pb-3">
              Reiki
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Eine Reiki-Behandlung schenkt dir tiefe Entspannung, neue Energie und innere Stärke für den Alltag. Es ist wie eine Auszeit für Körper, Geist und Seele, um Kraft zu tanken und zur Ruhe zu kommen. Reiki aktiviert deine Selbstheilungskräfte, löst Blockaden und hilft dir, Anspannungen loszulassen.
            </p>
            {/* <button className="font-medium rounded-full buttonservice">
            zur  Reiki erfahren
            </button> */}
          </div>

          {/* Service Card 3 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./i1.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Hypnose-Rückführung
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Eine Hypnose Rückführung hilft dir Erlebnisse aus der Vergangenheit positiv zu transformieren und diese dauerhaft zu lösen. Eine Rückführung geht zurück in dein jetziges oder auch in ein früheres Leben an die Ursache des zu behandelnden Themas.            </p>
            {/* <button className="font-medium rounded-full buttonservice">
            zur  Hypnose-Rückführung erfahren
            </button> */}
          </div>
          {/* Service Card 4 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./i5.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Channeling
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Beim Channeln ist man Kanal der geistigen Welt, um Botschaften zu empfangen. Ohne in den Seelenplan einzugreifen gibt es wichtige und tiefgreifende Antworten. Ein persönliches Channeling enthält Antworten auf deine individuellen Fragen.            </p>
            {/* <button className="font-medium rounded-full buttonservice">
            zur  Channeling erfahren
            </button> */}
          </div>
          {/* Service Card 5 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./i2.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Familienaufstellung
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Erkenne Verstrickungen in der Familie sowie übernommene Verhaltensmuster und löse sie. Aufstellungen sind sinnvoll bei Konflikten in der Familie oder bei Beziehungsproblemen. Aber auch um Ängste und Zwänge zu verlieren oder traumatische Erlebnisse zu verarbeiten.       </p>      {/* <button className="font-medium rounded-full buttonservice">
            zur Familienaufstellungen erfahren
            </button> */}
          </div>
          {/* Service Card 6 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./i6.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Rebirthing
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Rebirthing lässt dich deine Geburt oder die deines Kindes unter Hypnose neu erleben, positiv und in Harmonie. Schwierigkeiten bei Zulassen von Nähe oder loszulassen werden positiv transformiert. Anwendbar bei unerfülltem Kinderwunsch, Verlustängsten und Lebensängsten.      </p>       {/* <button className="font-medium rounded-full buttonservice">
            zur Rebirthing erfahren
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services; 